

















import Vue from "vue";
import Banner from "@/components/home/Banner.vue";
import WeddingDetails from "@/components/home/WeddingDetails.vue";
import Questions from "@/components/home/Questions.vue";

export default Vue.extend({
  name: "Home",
  components: { Questions, WeddingDetails, Banner },
});
