




















































import Vue from "vue";
import Names from "@/components/home/Names.vue";
export default Vue.extend({
  name: "Banner",
  components: { Names },
  methods: {
    onWeddingDetailsClicked() {
      this.$vuetify.goTo("#announcement-text", {
        duration: 500,
      });
    },
  },
});
