













































































































import Vue from "vue";
import Names from "@/components/home/Names.vue";

export default Vue.extend({
  name: "WeddingDetails",
  components: { Names },
});
